<template>
  <div>
    <v-simple-table>
      <template #default>
        <thead>
          <tr>
            <th
              v-for="(header, key) in headers"
              :key="key"
              class="text-left ap-dark-gray--text"
            >
              {{ header.label }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="itemsKey === 'index' ? index : item[itemsKey]"
            :class="{
              'cursor-pointer':
                !item.isDisabled && !item.isLoading && (item.to || item.event),
            }"
            @mouseover="hoverId = item.id"
            @mouseleave="hoverId = null"
            @click="onItemClicked(item)"
          >
            <td
              v-for="(value, columnIndex) in item.values"
              :key="`${item.id}_${headers[columnIndex].prop}`"
            >
              <DupeGridHeaderMatchConfidenceScore
                v-if="headers[columnIndex].prop === 'matchConfidenceScore'"
                is-only-icon
                :value="value.value"
              />

              <!-- Icon -->
              <v-icon v-else-if="value.isTypeIcon" :color="value.color">
                {{ value.icon }}
              </v-icon>

              <!-- Actions -->
              <v-icon
                v-for="(action, idx) in value.value"
                v-else-if="value.isTypeAction"
                :key="idx"
                :color="item.id === hoverId ? 'white' : undefined"
                style="z-index: 11"
                @click="$emit(action.event, item)"
              >
                {{ action.icon }}
              </v-icon>

              <!-- Text -->
              <span v-else :class="value.colorClass">
                {{ value.value }}
              </span>
            </td>

            <!-- Loading -->
            <div
              v-if="item.isLoading"
              class="d-flex align-center justify-center text-caption font-weight-black ap-white--text is-loading"
            >
              {{ item.loadingText }}
            </div>

            <!-- Hovering -->
            <div
              v-else-if="item.id === hoverId && item.hoverText"
              class="d-flex align-center justify-center text-caption font-weight-black ap-white--text is-hovering"
              :class="{ 'is-disabled': item.isDisabled }"
            >
              {{
                item.isDisabled ? item.hoverText : item.hoverText.toUpperCase()
              }}
            </div>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <ApPagination
      v-if="page"
      :value="page"
      :total-pages="totalPages"
      :total-items="totalItems"
      :items-per-page="itemsPerPage"
      :items-designation="itemsDesignation"
      is-range-pager-visible
      class="mt-3 justify-center justify-sm-space-between"
      @input="$emit('page', $event)"
    />
  </div>
</template>

<script>
import ApPagination from '@/components/common/ApPagination'
import DupeGridHeaderMatchConfidenceScore from '../dupegrid/DupeGridHeaderMatchConfidenceScore'

export default {
  components: {
    ApPagination,
    DupeGridHeaderMatchConfidenceScore,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemsKey: {
      type: String,
      default: 'id',
    },
    page: {
      type: Number,
      default: 0,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    itemsPerPage: {
      type: Number,
      default: 0,
    },
    itemsDesignation: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hoverId: null,
    }
  },
  methods: {
    onItemClicked(item) {
      if (!item.isLoading) {
        if (item.to) {
          this.$router.push(item.to)
        } else if (item.event) {
          this.$emit(item.event, item)
        }
        this.$emit('click', item)
      }
    },
  },
}
</script>

<style scoped>
tr {
  position: relative;
}

.is-loading {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(185, 185, 185, 0.8);
}

.is-hovering {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(34, 73, 121, 0.8);
}

.is-hovering.is-disabled {
  background-color: rgba(112, 110, 107, 0.8);
}
</style>
